import React, { useEffect } from "react";
import styled from "styled-components";

import { useModal } from "../../components/useModal";

import { Modal } from "../../components/site";
import { Container, Wrapper, Row, Box, Button } from "../../components/util";
import { SignupForm } from "../../components/forms";
import Layout from "../../components/layout";
import Image from "../../components/image";
import Seo from "../../components/seo";

import MyHRPlusPayHero from "../../images/logos/Other/MyHRPlusPayHero.svg";
import OfferGraphic_Image from "../../images/graphics/interest/MyHR_OfferGraphic.svg";
import OfferGraphic_Horiz_Image from "../../images/graphics/interest/MyHR_OfferGraphic_Horiz.svg";
// import FromFlexiTime from "../../images/logos/FlexiTime/FromFlexiTime.svg";

const FullContainer = styled(Container)`
  min-height: 100vh;
  width: 100%;
  max-width: 100%;
`;

const FormWrapper = styled(Wrapper)`
  display: flex;
  align-items: center;

  @media (min-width: ${(props) => props.theme.breakpoints.mobile}) {
    min-height: 100vh;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    align-items: flex-start;
  }
`;

const FormBox = styled(Box)`
  /* background-color: #fff; */
  padding: 30px;
  border-radius: ${(props) => props.theme.styles.borderRadius};
  margin-left: auto;
  z-index: 10;
  width: 600px;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
    padding: 10px;
  }
`;

const HeroImage = styled.div`
  z-index: 0;
  @media (min-width: ${(props) => props.theme.breakpoints.mobile}) {
    position: absolute;
    width: 100%;
    max-width: 1000px;
    top: 50%;
    right: 55%;
    transform: translateY(-50%);
  }

  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    max-width: 850px;
  }

  @media (max-width: 1200px) {
    max-width: 750px;
    right: 60%;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    max-width: 500px;
    right: 72%;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    position: relative;
    transform: translateY(0);
    width: 80%;
    right: 0;
    margin-bottom: 50px;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
`;

const OfferGraphic = styled.img`
  position: absolute;
  left: 0;
  bottom: 0;
  height: 100%;

  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    right: 72%;
    left: unset;
  }
`;

const OfferGraphicHoriz = styled.img`
  position: absolute;
  bottom: 0;
  width: 100%;
`;

const Or = styled.span`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 0 20px;
  color: #999;
`;

const Offer_MyHR = (props) => {
  const [itemModalOpen, setItemModalOpen, toggleModal] = useModal();

  useEffect(() => {
    if (typeof document !== "undefined" && typeof window !== "undefined") {
      window.localStorage.setItem("utm_term", "MyHR21");
    }
  }, []);

  return (
    <Layout>
      <Seo
        title="PayHero & MyHR | 2 Months Free Payroll Offer"
        pathname={props.location.pathname}
      />
      <FullContainer>
        <FormWrapper stackGap={40}>
          <FormBox stackGap={50}>
            <Box stackGap={20} className="-textCenter">
              <Row alignCenter stackGap={20} justify="center" noBreak>
                <img
                  src={MyHRPlusPayHero}
                  alt="PayHero | Compliant, Accurate & Easy to Use Payroll Software"
                  css={{ width: "90%", maxWidth: "350px" }}
                />
              </Row>
              <Box stackGap={7}>
                <h1>Two Months Free Payroll</h1>
                <h4>
                  Simplify employee onboarding, leave and payroll. MyHR
                  customers get the first two months of their PayHero
                  subscription for free. Simply use promo code{" "}
                  <span
                    css={{
                      padding: "1px 4px",
                      border: "solid 1px #ddd",
                      borderRadius: "5px",
                      fontWeight: "500",
                    }}
                  >
                    MYHR21
                  </span>{" "}
                  when subscribing to PayHero.
                </h4>
              </Box>
            </Box>
            <Box stackGap={40}>
              <SignupForm hideFormExtras center />
              <Box>
                <hr />
                <Or>or</Or>
              </Box>
              <Button className="grey -lg -full" onClick={toggleModal} atag>
                Book a Sales Call
              </Button>
              <p
                className="-textCenter"
                css={{ opacity: "0.6", marginTop: "30px !important" }}
              >
                * This offer is available only on new PayHero subscriptions for
                accounts that integrate with MyHR, and can’t be used with any
                other offer on the same subscription. After the usual 14-day
                free trial period use promo code MYHR21 when subscribing to
                claim the offer. The two-month free period begins when the
                subscription is purchased. This offer expires on 30 November
                2021 and PayHero may extend, cancel or amend the offer at any
                time.
              </p>
            </Box>
          </FormBox>
        </FormWrapper>
        <OfferGraphic
          src={OfferGraphic_Image}
          alt="Simplify employee onboarding, leave and payroll with MyHR & PayHero."
          className="hideOnMobile"
        />
        <OfferGraphicHoriz
          src={OfferGraphic_Horiz_Image}
          alt="Simplify employee onboarding, leave and payroll with MyHR & PayHero."
          className="showOnMobile"
        />
        <HeroImage>
          <Image
            filename="MyHRPayHero_Hero.png"
            alt="Simplify employee onboarding, leave and payroll with MyHR & PayHero."
            addShadow
            rounded
          />
        </HeroImage>
      </FullContainer>
      <Modal
        isActive={itemModalOpen}
        embedURL="https://calendly.com/d/ynv-22p-2r5"
        embedTitle="Book a Sales Call | PayHero Sales"
        handleClose={() => setItemModalOpen(false)}
      />
    </Layout>
  );
};

export default Offer_MyHR;
